import React from 'react'
import { Link } from 'react-router-dom'
export default function About() {
  return (
    <>
      <section className="page-title-section p_relative text-center" style={{ backgroundImage: "url(/assets/images/SHKAS.webp)" }}>
        <div className="shape-1" style={{ backgroundImage: "url(/assets/images/shape/shape-34.png)" }}></div>
        <div className="shape-2" style={{ backgroundImage: "url(/assets/images/shape/shape-35.png)" }}></div>
        <div className="auto-container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li className="dropdown"><Link to="/">Home</Link></li>
              <li>About</li>
            </ul>
            <h1>About us</h1>
          </div>
        </div>
      </section>
      <section className="about-section-3 sec-pad" id="cert1">
        <div className="pattern-1" style={{ backgroundImage: "url(assets/images/shape/shape-9.png)", }}></div>
        <div className="auto-container">
          <div className="row g">
            <div className="col-lg-3 ol-md-12 col-sm-12 d-flex align-items-center image-box-column">
              <div className="image-box">
                <div className="shape-1" style={{ backgroundImage: "url(assets/images/shape/shape-5.png);" }}></div>
                <div className="shape-2" style={{ backgroundImage: "url(assets/images/shape/shape-6.png);" }}></div>
                <figure className="image-1">
                  <img src="/assets/images/HEAVY-1.png" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <figure className="image-2">
                <img src="/assets/images/heavy.png" alt="" />

              </figure>
            </div>
            <div className="col-lg-5 ol-md-12 col-sm-12 d-flex align-items-center">
              <div className="about-content">
                <div className="title-box">
                  <h5 className="sec-sub-title">Company Overview</h5>
                  <div className="line-box">
                    <span className="line-1"></span>
                    <span className="line-2"></span>
                    <span className="line-3"></span>
                  </div>
                  <h2 className="sec-title">Biomax Biotechnics Private Limited</h2>
                  <p>At Biomax Biotechnics Private Limited, we are at the forefront of cutting-edge biotechnology innovation. As a leading biotech company, we are dedicated to revolutionizing the field through groundbreaking research, development, and the production of high-quality biopharmaceuticals and biotechnology solutions.</p>
                  <p>With a relentless pursuit of excellence, Biomax Biotechnics Private Limited is committed to pushing the boundaries of what is possible in the world of biotechnology. Our team of experienced scientists, researchers, and industry experts work collaboratively to create innovative solutions that address some of the most pressing challenges in healthcare, agriculture, and environmental sustainability.</p>
                </div>

                <div className="row lower-col">

                  <div className="col-lg-8 col-md-8 col-sm-12">
                    <div className="icon-box">
                      <i className="flaticon-phone"></i>
                      <div className="content">
                        <span>Need help?  Call us:</span>
                        <h5> +(91)-9316523497 </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="choose-section-3-1-humpty pt_100 pb_75 mb_100" style={{backgroundImage: "url(assets/images/shape/shape-33.png)"}}>
        <div class="auto-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <figure class="image">
                <img src="/assets/images/invented-img.jpg" alt="" />
              </figure>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="title-box mb_60 p_relative">
                <h5 class="sec-sub-title">Get to know us</h5>
                <div class="line-box">
                  <span class="line-1"></span>
                  <span class="line-2"></span>
                  <span class="line-3"></span>
                </div>
                <h2 class="sec-title text-white">Our Infrastructure</h2>
                <div class="shape-1" style={{backgroundImage: "url(assets/images/shape/shape-32.png)"}}></div>
              </div>
              <div class="row g-4 justify">
              <p class="text-white">
              Biotechnology companies typically have well-equipped laboratories where scientists and researchers conduct experiments, analyze data, and develop new products or technologies. These labs might include molecular biology, cell culture, protein analysis, and genetic engineering facilities.
              </p >
              <p class="text-white ">
              R&D facilities are where the company's scientific team works on innovating and developing new biotechnology products, processes, and solutions. This could involve drug discovery, genetic research, or agricultural biotechnology.
              </p>
              <p class="text-white">Biotech companies often have manufacturing facilities where they produce their pharmaceutical products, biopharmaceuticals, or other biotech-related products on a larger scale. These facilities need to adhere to strict quality and regulatory standards.</p>
              <p class="text-white">Quality control and quality assurance departments ensure that products meet regulatory and quality standards. This involves testing and analyzing products to ensure they are safe and effective.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
