import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homescreen from './screens/Home/Homescreen';
import About from './screens/About/About';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Contactscreen from './screens/Contact/Contactscreen';
import Directormessage from './screens/Director/Directormessage';
import Franchisescreen from './screens/Franchise/Franchisescreen';
import Certificate from './screens/Certificate/Certificate';
import Allcategory from './components/Allcategory';
import Vision from './screens/About/Vision';
import Cerificate from './screens/About/Cerificate';
import Product from './components/Product';
import Tablets from './screens/List/Tablets';
import Capsules from './screens/List/Capsules';
import Injections from './screens/Visuals/Injections';
import Others from './screens/Visuals/Others';
import Biova from './screens/Visuals/Biova';
import Discontinue from './screens/List/Discontinue';
function App() {
  return (

    <BrowserRouter>
      <div className='page-wrapper'>
        <Header />
        <Routes>
          <Route path='/' exact element={<Homescreen />} />
          <Route path='/about-us' element={<About />} /> 
          <Route path='/vision-mission-value' element={<Vision/>} />
          <Route path='/cerftificate' element={<Cerificate/>} />
          <Route path='/tablets-list' exact element={<Tablets/>} />
          <Route path='/capsules-list' element={<Capsules/>} />
          <Route path='/discontinue-products' element={<Discontinue/>} />
          <Route path='/cerftificate' element={<Cerificate/>} />
          <Route path='/contact-us' element={<Contactscreen />} />
          <Route path='/director-message' element={<Directormessage />} />
          <Route path='/pcd-pharma-fanchise' element={<Franchisescreen />} />
          <Route path='/injection-visual-aids' element={<Injections />} />
          <Route path='/other-visual-aids' element={<Others />} />
          <Route path='/biomax-visual-aids' element={<Biova />} />
          <Route path='/:category' element={<Allcategory />}/>
          <Route exact path='/product/:slug' element={<Product/>}/>
          {/* <Route path='/our-certificate' element={<Certificate />} /> */}
        </Routes>
        <Footer />
      </div>
    </BrowserRouter >

  );
}

export default App;
