import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
    return (
        <>

            <header className="main-header two">

                <div className="header-top">
                    <div className="top-inner">
                        <div className="top-left">
                            <ul className="info-list clearfix">
                                <li>Email: <Link to="mailto:care@biomaxbiotechnics.in">care@biomaxbiotechnics.in</Link></li>
                                <li>Address: <span>Motor Market, Manimajra Chandigarh - 160101, India</span></li>
                            </ul>
                        </div>
                        <div className="top-right">
                            <ul className="info-list clearfix">
                                <li>Phone: <span>+(91)-8146674934 </span></li>
                                <li>Phone: <span>+(91)-90238 22204 </span></li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="header-lower">
                    <div className="outer-box">
                        <div className="logo-box">
                            <figure className="logo"><Link to="index-2.html"><img src="/assets/images/smqONVT3.jpeg" alt="" /></Link>
                            </figure>
                        </div>
                        <div className="menu-area">

                            <div className="mobile-nav-toggler">
                                <i className="icon-bar"></i>
                                <i className="icon-bar"></i>
                                <i className="icon-bar"></i>
                            </div>
                            <nav className="main-menu navbar-expand-md navbar-light clearfix">
                                <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                    <ul className="navigation clearfix">
                                        <li className="current"><Link to="/">Home</Link>

                                        </li>
                                        <li className="dropdown"><Link to="/about-us">About us</Link>
                                            <ul>
                                                <li><Link to="/about-us">Company Overview</Link></li>
                                                <li><Link to="/vision-mission-value">Vision / Mission / Value</Link></li>
                                                <li><Link to="/cerftificate">Certificate</Link></li>

                                            </ul>
                                        </li>
                                        <li className="dropdown"><Link to="#">Services</Link>
                                            <ul>
                                                <li><Link to="/pcd-pharma-fanchise">PCD Pharma Franchise</Link></li>

                                            </ul>
                                        </li>

                                        <li className=" dropdown"><a href="#">What we offer</a>
                                            <ul>

                                                <li className="dropdown"><a href="#">Dosage wise</a>
                                                    <ul>
                                                        <li><Link to="/tablets">Tablets</Link></li>
                                                        <li><Link to="/syrup">Syrup</Link></li>
                                                        <li><Link to="/suspension">Suspension</Link></li>
                                                        <li><Link to="/dry-syrup">Dry Syrup</Link></li>
                                                        <li><Link to="/injections">Injections</Link></li>
                                                        <li><Link to="/respules">Respules</Link></li>
                                                        <li><Link to="/ivee">Ivee</Link></li>
                                                        <li><Link to="/sachet">Sachet</Link></li>
                                                        <li><Link to="/drops">Drop</Link></li>
                                                        <li><Link to="/gels">Gels</Link></li>
                                                        <li><Link to="/oil">Oil</Link></li>
                                                        <li><Link to="/powder">Powder</Link></li>
                                                        <li><Link to="/ayurvedic">Ayurvedic</Link></li>
                                                        <li><Link to="/cough-syrup">Cough Syrup</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown"><a href="#">Therapeutic wise</a>
                                                    <ul>
                                                        <li><Link to="/anti-allergy">Anti Allergy</Link></li>
                                                        <li><Link to="/laxative">Laxative</Link></li>
                                                        <li><Link to="/ortho">Ortho</Link></li>
                                                        <li><Link to="/gastro">Gastro</Link></li>
                                                        <li><Link to="/pediatric">Pediatric</Link></li>
                                                        <li><Link to="/anti-diarreahea">Anti diarreahea</Link></li>
                                                        <li><Link to="/dental">Dental</Link></li>
                                                        <li><Link to="/respiratory">Respiratory</Link></li>
                                                        <li><Link to="/antacid">Antacid</Link></li>
                                                        <li><Link to="/gyne">Gynee</Link></li>
                                                        <li><Link to="/cardiac">Cardiac</Link></li>
                                                        <li><Link to="/diabetic">Diabetic</Link></li>
                                                        <li><Link to="/neuro">Neuro</Link></li>
                                                        <li><Link to="/urology">Urology</Link></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="dropdown"><Link to="#">Approvel List</Link>
                                            <ul>
                                                <li><Link to="/tablets-list">Tablet Product List</Link></li>
                                                <li><Link to="/capsules-list">Capsule Product List</Link></li>
                                                <li><Link to="/discontinue-products">Discontinue Product List</Link></li>

                                            </ul>
                                        </li>
                                        <li className="dropdown"><Link to="#">Visual Aids</Link>
                                            <ul>
                                                <li><Link to="/injection-visual-aids">Injection Visual Aids</Link></li>
                                                <li><Link to="/other-visual-aids">Other Visual Aids</Link></li>
                                                <li><Link to="/biomax-visual-aids">Biomax Visual Aids</Link></li>
                                                

                                            </ul>

                                        </li>
                                        <li className=""><Link to="#">Blog</Link>

                                        </li>

                                    </ul>
                                </div>
                            </nav>
                            <div className="btn-box">
                                <Link to="/contact-us" className="theme-btn btn-one">Get A Quote</Link>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </header>



            <nav className="mobile">
                <div className="wrapper">
                    <div className="logo"><Link to="/"><img src="/assets/images/smqONVT3.jpeg" alt="" width={50} /></Link></div>
                    <input type="radio" name="slider" id="menu-btn" />
                    <input type="radio" name="slider" id="close-btn" />
                    <ul class="nav-links">
                        <label for="close-btn" className="btn close-btn"><i className="fas fa-times"></i></label>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="#"  className="desktop-item">About Us</Link> 
                        <input type="checkbox"  id="showDrop" />
                            <label for="showDrop" className="mobile-item">About Us</label>
                            <ul className="drop-menua">
                                <li><Link to="/about-us">Company Overview</Link></li>
                                <li><Link to="/vision-mission-value">Vision / Mission / Value</Link></li>
                                <li><Link to="/cerftificate">Certificate</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="#" className="desktop-item">Dosage wise</Link>
                            <input type="checkbox" id="showDrop" />
                            <label for="showDrop" className="mobile-item">Dosage wise</label>
                            <ul className="drop-menud">
                                <li><Link to="/tablets">Tablets</Link></li>
                                <li><Link to="/syrup">Syrup</Link></li>
                                <li><Link to="/suspension">Suspension</Link></li>
                                <li><Link to="/dry-syrup">Dry Syrup</Link></li>
                                <li><Link to="/injections">Injections</Link></li>
                                <li><Link to="/respules">Respules</Link></li>
                                <li><Link to="/ivee">Ivee</Link></li>
                                <li><Link to="/sachet">Sachet</Link></li>
                                <li><Link to="/drops">Drop</Link></li>
                                <li><Link to="/gels">Gels</Link></li>
                                <li><Link to="/oil">Oil</Link></li>
                                <li><Link to="/powder">Powder</Link></li>
                                <li><Link to="/ayurvedic">Ayurvedic</Link></li>
                                <li><Link to="/cough-syrup">Cough Syrup</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="#" className="desktop-item">Therapeutic wise</Link>
                            <input type="checkbox" id="showDrop" />
                            <label for="showDrop" className="mobile-item">Therapeutic wise</label>
                            <ul className="drop-menus">
                                <li><Link to="/anti-allergy">Anti Allergy</Link></li>
                                <li><Link to="/laxative">Laxative</Link></li>
                                <li><Link to="/ortho">Ortho</Link></li>
                                <li><Link to="/gastro">Gastro</Link></li>
                                <li><Link to="/pediatric">Pediatric</Link></li>
                                <li><Link to="/anti-diarreahea">Anti diarreahea</Link></li>
                                <li><Link to="/dental">Dental</Link></li>
                                <li><Link to="/respiratory">Respiratory</Link></li>
                                <li><Link to="/antacid">Antacid</Link></li>
                                <li><Link to="/gyne">Gynee</Link></li>
                                <li><Link to="/cardiac">Cardiac</Link></li>
                                <li><Link to="/diabetic">Diabetic</Link></li>
                                <li><Link to="/neuro">Neuro</Link></li>
                                <li><Link to="/urology">Urology</Link></li>

                            </ul>
                        </li>

                        <li><Link to="/pcd-pharma-fanchise">PCD Pharma Franchise</Link></li>
                        <li>
                            <Link to="#" className="desktop-item">Visual Aids</Link>
                            <input type="checkbox" id="showDrop" />
                            <label for="showDrop" className="mobile-item">Visual Aids</label>
                            <ul className="drop-menuv">
                                <li><Link to="/injection-visual-aids">Injection Visual Aids</Link></li>
                                <li><Link to="/other-visual-aids">Other Visual Aids</Link></li>
                                <li><Link to="/biomax-visual-aids">Biomax Visual Aids</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/contact-us">Contact us</Link></li>

                    </ul>
                    <label for="menu-btn" className="btn menu-btn"><i className="fas fa-bars"></i></label>
                </div>

            </nav>

            <div className='rightbar'>
                <ul>
                    <li>
                        <Link to='tel:+91 81466 74934'>
                            <img width="40px" alt="" src="/assets/images/tele.png" />
                        </Link>
                    </li>
                    <li className='none'>
                        <a href="https://api.whatsapp.com/send?phone=+(91)-8146674934 &amp;text=Biomax Biotechnics Private Limited" data-url="https://api.whatsapp.com/send?phone=+(91)-8146674934 &amp;text=Biomax Biotechnics Private Limited" >
                            <img alt="" width="40px" src="/assets/images/what.png" />
                        </a>
                    </li>
                </ul>
            </div>

            <section>
                <div class="ewChat">

                    <div class="ewChatBox ewc_hidden" aria-hidden="true">

                        <section class="ewChatHeader">
                            <div class="ewChatAvatar">
                                <div class="John">
                                    <img src="https://encuerpoyalma.000webhostapp.com/Assets/Img/Memoji/Memoji2.png" alt="Memoji de cliente" />
                                </div>
                                <div>
                                    <h2>Welcome to Biomax Biotechnics</h2>

                                </div>
                            </div>
                        </section>

                        <div>
                            <div class="ewChatBubble">
                                <p>Hello....<br />
                                    How May Help You </p>

                            </div>
                        </div>
                    </div>

                    <div class="ewChatInput ewc_hidden" aria-hidden="true">
                        <a class="btn btn-primary" href="https://api.whatsapp.com/send?phone=+(91)-81466 74934 &text=Hello Biomax Biotechnics Private Limited" role="button">Start Chat</a>

                    </div>
                </div>
            </section>


        </>
    )
}
